import {Observable} from 'rxjs';
import {CustomDataSource, Page} from './CustomDataSource';
import {DeviceService} from '../services/devices.service';
import {map} from 'rxjs/operators';

export class DeviceDataSource extends CustomDataSource<Device> {

  constructor(private deviceService: DeviceService) {
    super();
  }

  loadData(search: string, page: number, size: number, sortField: string, sortDir: string, options: any): Observable<Page<Device>> {
    let observable: Observable<any>;
    // if (options.child) {
    //   observable = this.deviceService.getAllDevicesByParentDevice(options.id, page, size, sortField, sortDir);
    // } else {
    //   observable = this.deviceService.getAllDevicesBySite(options.id, page, size, sortField, sortDir);
    // }
    // return observable.pipe(map(res => ({data: res.devices, total: res.total})));
    return observable.pipe(map(res => ({data: res.devices, total: res.total})));
  }
}

export interface Device {
  id: string;
  name: string;
  parentId: string;
  manufacturer: string;
  model: string;
  zoneName: string;
  floorName: string;
  status: string;
}
